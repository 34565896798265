export const emailRules = () => [
  (v) => !!v || '', // Обязательне поле
  (v) => /.+@.+\..+/.test(v) || '', // E-mail должен быть валидным
];

export const passwordRules = () => [
  (v) => (/^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])[a-zA-Z0-9!"$%&'()+,-/:;<>=?@{}[_`~\]]{8,}$/).test(v) || 'Ненадежный пароль',
];

export const isEqual = (val) => [
  (v) => !!v || '', // Обязательне поле
  (v) => v === val || 'Пароли не совпадают', // Поле равно значению
];

export const nameRules = () => [
  (v) => !!v || 'Имя обязательно', // Имя обязательно
];

export const statusCode = (res) => {
  if (!res) return false;
  let result = false;
  switch (res.status) {
    case 200: {
      result = true;
      break;
    }
    case 201: {
      result = true;
      break;
    }
    case 400: {
      result = false;
      break;
    }
    case 401: {
      result = false;
      break;
    }
    case 403: {
      result = false;
      break;
    }
    case 500: {
      result = false;
      break;
    }
    default: {
      result = false;
    }
  }

  return result;
};
