import api from '@/services/api/projects';

export default {
  namespaced: true,
  state: {
    projects: [],
    project: {},
    projectTasks: [],
    fetchProgress: false,
  },
  actions: {
    async getProjects({ commit }, payload) {
      commit('setFetchProgress', true);
      try {
        const res = await api.getProjects(payload);
        commit('setProjects', res.data.results);
        return res.data;
      } catch (error) {
        return Promise.reject(error);
      } finally {
        commit('setFetchProgress', false);
      }
    },

    getProjectFromJIRA(content, payload) {
      return api.getProjectFromJIRA(payload);
    },

    async getProjectTasks({ commit }, payload) {
      try {
        const res = await api.getProjectTasks(payload);
        commit('setProjectTasks', res.data);
        return res;
      } catch (error) {
        return error;
      }
    },

    async getProjectTask(context, payload) {
      try {
        const res = await api.getProjectTask(payload);
        return res;
      } catch (error) {
        return error;
      }
    },

    async getProject({ commit }, payload) {
      try {
        const res = await api.getProject(payload);
        commit('setProject', res.data);
        return res.data;
      } catch (error) {
        return Promise.reject(error);
      }
    },

    async createProject(context, payload) {
      try {
        const res = await api.createProject(payload);
        return res;
      } catch (error) {
        return error;
      }
    },

    async deleteProject({ dispatch }, payload) {
      try {
        const res = await api.deleteProject(payload);
        dispatch('getProjects');
        return res;
      } catch (error) {
        return error;
      }
    },
    async editProject(context, payload) {
      try {
        const res = await api.editProject(payload);
        return res;
      } catch (error) {
        return {
          error: true,
          data: error.response.data,
        };
      }
    },

    async editProjectTask(context, { payload, pid }) {
      try {
        const res = await api.editProjectTask(payload, pid);
        return res;
      } catch (error) {
        return error;
      }
    },

    async createProjectTask(context, payload) {
      try {
        const res = await api.createProjectTask(payload);
        return res;
      } catch (error) {
        return error;
      }
    },

    async deleteProjectTask(context, payload) {
      try {
        const res = await api.deleteProjectTask(payload);
        return res;
      } catch (error) {
        return error;
      }
    },
  },
  mutations: {
    setProjects: (state, value) => {
      state.projects = value;
    },

    setProject: (state, value) => {
      state.project = value;
    },

    setProjectTasks: (state, value) => {
      state.projectTasks = value;
    },
    setFetchProgress: (state, value) => {
      state.fetchProgress = value;
    },
  },
  getters: {
    fetchProgress: (state) => state.fetchProgress,
  },
};
