<template>
  <v-app>
    <v-app-bar app clipped-left color="#2D2D2D" dark elevation="0" class="mainHeader">
      <div class="titleWithIcon">
        <img :src="logo" class="app-bar__logo" alt="logo"/>
      </div>
    </v-app-bar>

    <v-main>
      <router-view />
    </v-main>

    <Footer />
  </v-app>
</template>

<script>
import logo from '@/assets/img/logoBSL.svg';

export default {
  name: 'EmptyLayout',
  data: () => ({
    logo,
  }),
};
</script>
