<template>
  <v-app>
    <v-app-bar
      app
      clipped-left
      color="#2D2D2D"
      dark
      elevation="0"
      class="mainHeader"
    >
      <v-app-bar-nav-icon @click="drawer = !drawer"></v-app-bar-nav-icon>

      <div class="titleWithIcon">
        <img
          :src="logo"
          class="app-bar__logo"
          alt="logo"
        />
      </div>

      <v-btn icon class="ml-auto" @click="onLogout">
        <v-icon>mdi-logout</v-icon>
      </v-btn>
    </v-app-bar>
    <v-navigation-drawer
      v-model="drawer"
      app
      clipped
    >
      <Navbar />
    </v-navigation-drawer>

    <v-main class="mainWrap">
      <router-view />
    </v-main>
  </v-app>
</template>

<script>
import { mapActions } from 'vuex';
import Navbar from '@/components/Navbar.vue';
import logo from '@/assets/img/logoBSL.svg';

export default {
  name: 'AdminLayout',
  data() {
    return {
      drawer: null,
      logo,
    };
  },

  components: { Navbar },
  methods: {
    ...mapActions({
      logout: 'auth/logout',
    }),
    onLogout() {
      this.logout().then(() => {
        this.$router.push({ name: 'Login' });
      });
    },
  },
};
</script>

<style lang="scss">
@import "src/assets/styles/variables";
.app-bar {
  background-color: $toolbar;
}

.mainHeader {
  .titleWithIcon {
    display: flex;
    align-items: center;
    .app-bar__logo {
      width: 100%;
      height: 20px;
      max-width: 200px;
      margin-left: 10px;
    }
  }
  .v-toolbar__content {
    padding: 4px 30px !important;
  }
}
.mainWrap {
  max-height: 100vh;
  .v-main__wrap {
    display: flex;
    background-color: $main-background;
  }
}
</style>
