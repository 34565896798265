<template>
  <div class="navbar-list">
    <div
      v-for="el in items"
      :key="el.title"
      @click="changeRouter(el.url)"
      :class="{active: checkItemsInclude(el.keyWordsForUrl) }"
      class="navbar-list__item"
    >
      <v-list-item-icon>
        <v-icon :color="checkItemsInclude(el.keyWordsForUrl) ? '#FF7303' : ''">{{el.icon}}</v-icon>
      </v-list-item-icon>
      <p>{{el.title}}</p>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Navbar',

  data() {
    return {
      selectedItem: 1,
      items: [],
    };
  },

  created() {
    this.createNavList();
  },

  methods: {
    changeRouter(el) {
      if (el !== this.$route.path) this.$router.push(el);
    },
    checkItemsInclude(val) {
      return val.some((el) => this.$route.path.includes(el));
    },

    createNavList() {
      this.items.splice(0);
      this.$router.options.routes.filter((f) => !f.hideOnNavbar).forEach((route) => {
        if (route.meta.permissions && this.hasRoleAccess(route.meta.permissions)) {
          this.items.push({
            title: route.title,
            url: route.path,
            keyWordsForUrl: [route.path],
            icon: route.icon,
          });
        }
      });
    },
  },

  watch: {
    permissions: {
      deep: true,
      handler() {
        this.createNavList();
      },
    },
  },
};
</script>

<style scoped lang="scss">
@import "src/assets/styles/variables";
.navbar-list {
  padding-top: 30px;
  height: 100%;
  background: #f6f6f6 !important;
  user-select: none;
}

.navbar-list__item {
  display: flex;
  align-items: center;
  cursor: pointer;
  padding: 0 30px;
  transition: 0.3s;

  .v-list-item__icon {
    margin-left: 0 !important;
  }

  p {
    margin-bottom: 0;
    font-weight: 500;
    font-size: 16px;
    line-height: 19px;
  }

  &:hover {
    background-color: #eeeeee;
    transition: 0.3s;
  }
}

.navbar-list__item.active {
  background-color: rgba(255, 115, 3, 0.12);
  p {
    color: $orange;
  }
}
</style>
