import api from '@/services/api/auth';
import { parseJwt } from '@/core/helpers';
import { getToken, saveTokens, destroyTokens } from '../services/jwt.service';

const token = getToken();
const refreshToken = getToken(true);
const jwt = parseJwt(token);
const permissions = jwt ? jwt.user_permissions : [];
const initialState = () => ({
  initialized: false,
  loading: false,
  errors: [],
  user: null,
  token,
  refreshToken,
  permissions,
});

export default {
  namespaced: true,
  state: initialState,
  actions: {
    async login({ commit }, { email, password }) {
      const data = { email, password };
      commit('setLoading', true);
      commit('setError', []);
      try {
        const response = await api.login(data);
        const { access, refresh } = response.data;
        commit('setToken', { access, refresh });
        return response.data;
        // dispatch('loadMe');
      } catch (err) {
        commit('setError', Object.entries(err.data));
        throw err;
      } finally {
        commit('setLoading', false);
      }
    },

    async refresh({ commit, state }) {
      const data = { refresh: state.refreshToken };
      commit('setError', []);
      try {
        const response = await api.refresh(data);
        const { access, refresh } = response.data;
        commit('setToken', { access, refresh });
        return response.data;
      } catch (err) {
        commit('setError', Object.entries(err.data));
        throw err;
      }
    },

    async logout({ getters, commit }) {
      commit('setError', []);
      if (getters.loggedIn) {
        commit('setLoading', true);
        try {
          await api.logout();
        } catch (err) {
          console.log(err);
        }
        destroyTokens();
        commit('logOut');
        commit('setLoading', false);
        return true;
      }
    },
    async loadMe({ state, commit }) {
      if (state.token) {
        commit('setLoading', true);
        commit('setError', []);
        try {
          const response = await api.get('/users/get-me');
          commit('setUser', response);
        } catch (err) {
          commit('setError', Object.entries(err.data));
          commit('logOut');
          throw err;
        } finally {
          commit('setLoading', false);
        }
      }
    },
    async register({ commit }, credentials) {
      try {
        const response = await api.post('users', { user: credentials });
        commit('setUser', response.data.user);
      } catch (err) {
        commit('setError', err.data.errors);
      }
    },
    async updateUser({ commit }, payload) {
      const {
        email, username, password,
      } = payload;
      const user = {
        email,
        username,
      };
      if (password) {
        user.password = password;
      }

      try {
        const response = await api.put('user', user);
        commit('setUser', response.data.user);
      } catch (err) {
        commit('setError', err.data.errors);
        throw err;
      }
    },
    async issuePasswordReset({ commit }, email) {
      commit('setLoading', true);
      commit('setError', []);
      try {
        await api.issuePasswordReset({ email });
      } catch (err) {
        commit('setError', err.data);
        commit('setError', Object.entries(err.data));
        throw err;
      } finally {
        commit('setLoading', false);
      }
    },
    async passwordReset({ commit }, data) {
      commit('setLoading', true);
      commit('setError', []);
      try {
        await api.passwordReset(data);
      } catch (err) {
        commit('setError', Object.entries(err.data));
        throw err;
      } finally {
        commit('setLoading', false);
      }
    },
    clearError({ commit }) {
      commit('setError', []);
    },
  },
  mutations: {
    setPermissions(state, data) {
      state.permissions = data;
    },

    setInitialized(state) {
      state.initialized = true;
    },
    setLoading(state, data) {
      state.loading = data;
    },
    setError(state, error) {
      state.errors = error;
    },
    setUser(state, user) {
      state.user = user;
    },
    setToken(state, data) {
      state.token = data.access;
      state.refreshToken = data.refresh;
      state.permissions = parseJwt(data.access).user_permissions;
      saveTokens(data);
    },
    logOut(state) {
      state.user = null;
      state.token = '';
      state.refreshToken = '';
      state.permissions = [];
    },
  },
  getters: {
    initialized: (state) => state.initialized,
    loading: (state) => state.loading,
    errors: (state) => state.errors,
    token: (state) => state.token,
    user: (state) => state.user,
    loggedIn: (state) => !!state.token,
    hasRoleAccess: (state) => (permissions) => {
      if (state.permissions.some((r) => permissions.includes(r))) return true;
      return false;
    },
  },
};
