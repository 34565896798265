import axios from 'axios';
import { getToken } from '../jwt.service';
/**
 * Сконфигурированный axios клиент
 * */

const axiosClient = axios.create({
  timeout: 100000,
  baseURL: process.env.VUE_APP_BASE_API_URL,
  headers: {
    'Content-Type': 'application/json',
  },
  maxContentLength: 10000,
});

axiosClient.interceptors.request.use((config) => {
  const token = getToken();
  if (token) config.headers.Authorization = `Bearer ${token}`;
  return config;
});

axiosClient.interceptors.response.use(
  (resp) => resp,
  (err) => {
    if (
      err.response?.status === 403
      && err.response?.data?.change_permission
      && err.response.data.change_permission.length > 0
    ) {
      window['global-emitter'].$emit('showPermissionsChange', err.response.data);
      return Promise.resolve(err.response);
    }

    if (
      err.response?.status === 403
      && err.response?.data?.change_permission
      && err.response.data.change_permission.length === 0
    ) {
      window['global-emitter'].$emit('err403');
    }

    if (err.response?.status >= 500) {
      // eslint-disable-next-line prefer-promise-reject-errors
      return Promise.reject({
        data: { server: [`Ошибка статус ${err.status}`] },
      });
    }
    if (err.response) {
      return Promise.reject(err.response);
    }
    // eslint-disable-next-line prefer-promise-reject-errors
    return Promise.reject({
      data: { not_server_response: ['Ошибка соединения с сервером'] },
    });
  },
);

export default axiosClient;
