import api from '@/services/api/roles';
import { permissions } from '@/core/constants/permissions';

const permissionsObject = {};
permissions.forEach((f) => {
  f.modulePermissions.forEach((m) => {
    permissionsObject[m.id] = false;
  });
});

export default {
  namespaced: true,
  state: {
    roles: [],
    role: {},
    roleTempMain: null,
    roleTempPermissions: null,
    fetchProgress: false,
    permissions,
    permissionsObject,
  },
  actions: {
    async getRoles({ commit }, payload) {
      commit('setFetchProgress', true);
      try {
        const res = await api.getRoles(payload);
        commit('setRoles', res.data);
        return res.data;
      } catch (error) {
        return Promise.reject(error);
      } finally {
        commit('setFetchProgress', false);
      }
    },

    async getRole({ commit }, payload) {
      try {
        const res = await api.getRole(payload);
        commit('setRole', res.data);
        return res.data;
      } catch (error) {
        return Promise.reject(error);
      }
    },

    async createRole({ commit }, payload) {
      try {
        const res = await api.createRole(payload);
        commit('setRoleTempMain', null);
        commit('setRoleTempPermissions', null);
        return res;
      } catch (error) {
        return error;
      }
    },

    async deleteRole({ dispatch }, payload) {
      try {
        const res = await api.deleteRole(payload);
        dispatch('getRoles');
        return res;
      } catch (error) {
        return error;
      }
    },
    async editRole({ commit }, payload) {
      try {
        const res = await api.editRole(payload);
        commit('setRoleTempMain', null);
        commit('setRoleTempPermissions', null);
        return res;
      } catch (error) {
        return {
          error: true,
          data: error.response.data,
        };
      }
    },
  },
  mutations: {
    setRoles: (state, value) => {
      state.roles = value;
    },

    setRole: (state, value) => {
      state.role = value;
    },

    setFetchProgress: (state, value) => {
      state.fetchProgress = value;
    },

    setRoleTempMain: (state, value) => {
      if (value) {
        state.roleTempMain = {
          ...value,
        };
      } else {
        state.roleTempMain = null;
      }
    },
    setRoleTempPermissions: (state, value) => {
      if (value) {
        state.roleTempPermissions = {
          ...value,
        };
      } else {
        state.roleTempPermissions = null;
      }
    },
  },
  getters: {
    fetchProgress: (state) => state.fetchProgress,
    getPermissionName: (state) => (permission) => {
      const module = state.permissions.find((f) => f.modulePermissions.find((p) => p.id === permission));
      return module ? `${module.modulePermissions.find((f) => f.id === permission).label} (${module.moduleName})` : null;
    },
  },
};
