import api from '@/services/api/staff';

export default {
  namespaced: true,
  state: {
    // Данные пока моковые, нуждаются в доработке, когда будет бэк.
    positions: [],
    managers: [],
    specialists: [],
    isOpenAllItem: false,
    tableHeader: {
      staff: 'Сотрудник',
      position: 'Дол-ть',
      items: ['31.05.21', '01.06.21', '02.06.21', '03.06.21', '04.06.21', '05.06.21', '06.06.21'],
      total: 'Итого',
      deviation: 'Откл',
    },
    users1: [
      {
        id: 1,
        name: 'Миронова Настя Алексеевна',
        position: 'Designer',
        outsourcing: true,
        items: [
          {
            title: 'monday', value: 24, dayOff: false, vacation: false,
          },
          {
            title: 'tuesday', value: 8, dayOff: false, vacation: false,
          },
          {
            title: 'wednesday', value: 6, dayOff: false, vacation: false,
          },
          {
            title: 'thursday', value: 8, dayOff: false, vacation: false,
          },
          {
            title: 'friday', value: 12, dayOff: false, vacation: false,
          },
          {
            title: 'saturday', value: null, dayOff: true, vacation: false,
          },
          {
            title: 'sunday', value: null, dayOff: true, vacation: false,
          },
        ],
        total: '33',
        deviation: '15%',
        projects: [
          {
            title: 'Helptomama',
            items: [
              {
                title: 'monday', value: 24, projectId: 1, userId: 1, dayOff: false, vacation: false,
              },
              {
                title: 'tuesday',
                value: null,
                projectId: 1,
                userId: 1,
                dayOff: false,
                vacation: false,
              },
              {
                title: 'wednesday',
                value: 1,
                projectId: 1,
                userId: 1,
                dayOff: false,
                vacation: false,
              },
              {
                title: 'thursday',
                value: 4,
                projectId: 1,
                userId: 1,
                dayOff: false,
                vacation: false,
              },
              {
                title: 'friday', value: 8, projectId: 1, userId: 1, dayOff: false, vacation: false,
              },
              {
                title: 'saturday',
                value: null,
                projectId: 1,
                userId: 1,
                dayOff: true,
                vacation: false,
              },
              {
                title: 'sunday',
                value: null,
                projectId: 1,
                userId: 1,
                dayOff: true,
                vacation: false,
              },
            ],
            total: '33',
            deviation: '5%',
          },
          {
            title: 'Coral Club',
            items: [
              {
                title: 'monday',
                value: null,
                projectId: 2,
                userId: 1,
                dayOff: false,
                vacation: false,
              },
              {
                title: 'tuesday', value: 8, projectId: 2, userId: 1, dayOff: false, vacation: false,
              },
              {
                title: 'wednesday',
                value: 5,
                projectId: 2,
                userId: 1,
                dayOff: false,
                vacation: false,
              },
              {
                title: 'thursday',
                value: 4,
                projectId: 2,
                userId: 1,
                dayOff: false,
                vacation: false,
              },
              {
                title: 'friday', value: 4, projectId: 2, userId: 1, dayOff: false, vacation: false,
              },
              {
                title: 'saturday',
                value: null,
                projectId: 2,
                userId: 1,
                dayOff: true,
                vacation: false,
              },
              {
                title: 'sunday',
                value: null,
                projectId: 2,
                userId: 1,
                dayOff: true,
                vacation: false,
              },
            ],
            total: '33',
            deviation: '10%',
          },
        ],
      },
      {
        id: 2,
        name: 'Ратошкина Елена Алексеевна ',
        position: 'Front',
        outsourcing: false,
        items: [
          {
            title: 'monday', value: 9, dayOff: false, vacation: false,
          },
          {
            title: 'tuesday', value: 6, dayOff: false, vacation: false,
          },
          {
            title: 'wednesday', value: 8, dayOff: false, vacation: false,
          },
          {
            title: 'thursday', value: null, dayOff: false, vacation: true,
          },
          {
            title: 'friday', value: null, dayOff: false, vacation: true,
          },
          {
            title: 'saturday', value: null, dayOff: false, vacation: true,
          },
          {
            title: 'sunday', value: null, dayOff: false, vacation: true,
          },
        ],
        total: '33',
        deviation: '13%',
        projects: [
          {
            title: 'Magnit',
            items: [
              {
                title: 'monday', value: 8, dayOff: false, vacation: false,
              },
              {
                title: 'tuesday', value: null, dayOff: false, vacation: false,
              },
              {
                title: 'wednesday', value: 8, dayOff: false, vacation: false,
              },
              {
                title: 'thursday', value: null, dayOff: false, vacation: true,
              },
              {
                title: 'friday', value: null, dayOff: false, vacation: true,
              },
              {
                title: 'saturday', value: null, dayOff: false, vacation: true,
              },
              {
                title: 'sunday', value: null, dayOff: false, vacation: true,
              },
            ],
            total: '33',
            deviation: '5%',
          },
          {
            title: 'World Skills',
            items: [
              {
                title: 'monday', value: 1, dayOff: false, vacation: false,
              },
              {
                title: 'tuesday', value: 6, dayOff: false, vacation: false,
              },
              {
                title: 'wednesday', value: null, dayOff: false, vacation: false,
              },
              {
                title: 'thursday', value: null, dayOff: false, vacation: true,
              },
              {
                title: 'friday', value: null, dayOff: false, vacation: true,
              },
              {
                title: 'saturday', value: null, dayOff: false, vacation: true,
              },
              {
                title: 'sunday', value: null, dayOff: false, vacation: true,
              },
            ],
            total: '33',
            deviation: '10%',
          },
        ],
      },
      {
        id: 3,
        name: 'Давыдов Олег Алексеевич',
        position: 'QA',
        outsourcing: true,
        items: [
          {
            title: 'monday', value: 8, dayOff: false, vacation: false,
          },
          {
            title: 'tuesday', value: 8, dayOff: false, vacation: false,
          },
          {
            title: 'wednesday', value: 8, dayOff: false, vacation: false,
          },
          {
            title: 'thursday', value: 8, dayOff: false, vacation: false,
          },
          {
            title: 'friday', value: 8, dayOff: false, vacation: false,
          },
          {
            title: 'saturday', value: null, dayOff: true, vacation: false,
          },
          {
            title: 'sunday', value: null, dayOff: true, vacation: false,
          },
        ],
        total: '33',
        deviation: '15%',
        projects: [
          {
            title: 'Helptomama',
            items: [
              {
                title: 'monday', value: null, dayOff: false, vacation: false,
              },
              {
                title: 'tuesday', value: 8, dayOff: false, vacation: false,
              },
              {
                title: 'wednesday', value: 8, dayOff: false, vacation: false,
              },
              {
                title: 'thursday', value: null, dayOff: false, vacation: false,
              },
              {
                title: 'friday', value: null, dayOff: false, vacation: false,
              },
              {
                title: 'saturday', value: null, dayOff: true, vacation: false,
              },
              {
                title: 'sunday', value: null, dayOff: true, vacation: false,
              },
            ],
            total: '33',
            deviation: '5%',
          },
          {
            title: 'Magnit',
            items: [
              { title: 'monday', value: 8 },
              {
                title: 'tuesday', value: null, dayOff: false, vacation: false,
              },
              {
                title: 'wednesday', value: null, dayOff: false, vacation: false,
              },
              {
                title: 'thursday', value: null, dayOff: false, vacation: false,
              },
              {
                title: 'friday', value: null, dayOff: false, vacation: false,
              },
              {
                title: 'saturday', value: null, dayOff: true, vacation: false,
              },
              {
                title: 'sunday', value: null, dayOff: true, vacation: false,
              },
            ],
            total: '33',
            deviation: '10%',
          },
          {
            title: 'Tinkoff',
            items: [
              {
                title: 'monday', value: null, dayOff: false, vacation: false,
              },
              {
                title: 'tuesday', value: null, dayOff: false, vacation: false,
              },
              {
                title: 'wednesday', value: null, dayOff: false, vacation: false,
              },
              {
                title: 'thursday', value: 8, dayOff: false, vacation: false,
              },
              {
                title: 'friday', value: 8, dayOff: false, vacation: false,
              },
              {
                title: 'saturday', value: null, dayOff: true, vacation: false,
              },
              {
                title: 'sunday', value: null, dayOff: true, vacation: false,
              },
            ],
            total: '33',
            deviation: '10%',
          },
        ],
      },
    ],
    specialistKindProgress: false,
    managersProgress: false,
  },

  actions: {
    async createUser(context, payload) {
      try {
        return await api.createUser(payload);
      } catch (error) {
        return error;
      }
    },
    async createPM(context, payload) {
      try {
        return await api.createPM(payload);
      } catch (error) {
        return error;
      }
    },
    async getPositions({ commit }) {
      try {
        const res = await api.getPositions();
        commit('setPositions', res.data);
        return res;
      } catch (error) {
        return error;
      }
    },
    async addPositions(context, payload) {
      try {
        return await api.addPositions(payload);
      } catch (error) {
        return error;
      }
    },

    async getManagers({ commit }, payload) {
      commit('setManagersProgress', true);
      try {
        const res = await api.getManagers(payload);
        commit('setManagers', res.data);
        return res.data;
      } catch (error) {
        return error;
      } finally {
        commit('setManagersProgress', false);
      }
    },

    async putManagers(context, payload) {
      try {
        const res = await api.putManagers(payload);
        return res;
      } catch (error) {
        return error;
      }
    },

    async getSpecialistKind({ commit }) {
      commit('setSpecialistKindProgress', true);
      try {
        const res = await api.getSpecialistKind();
        commit('setSpecialistKind', res.data);
        return res;
      } catch (error) {
        return Promise.reject(error);
      } finally {
        commit('setSpecialistKindProgress', false);
      }
    },
  },
  mutations: {
    setPositions: (state, value) => {
      state.positions = value;
    },
    addPosition: (state) => {
      state.positions.push({ title: '', rate: '' });
    },
    toggleAllItem: (state) => {
      state.isOpenAllItem = !state.isOpenAllItem;
    },

    setManagers: (state, payload) => {
      state.managers = payload;
    },

    setSpecialistKind: (state, payload) => {
      state.specialists = payload;
    },
    setSpecialistKindProgress: (state, value) => {
      state.specialistKindProgress = value;
    },
    setManagersProgress: (state, value) => {
      state.managersProgress = value;
    },
  },

  getters: {
    specialistKindProgress: (state) => state.specialistKindProgress,
    managersProgress: (state) => state.managersProgress,
    getUsersList: (state) => state.users1.map((el) => ({
      id: el.id,
      name: el.name,
    })),
  },
};
