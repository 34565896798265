// eslint-disable-next-line import/no-extraneous-dependencies
import '@mdi/font/css/materialdesignicons.css';
import Vue from 'vue';
import Vuetify from 'vuetify';

Vue.use(Vuetify);

const opts = {
  theme: {
    themes: {
      light: {
        // primary: '#18A0FB',
        // secondary: '#18A0FB',
        // accent: '#8c9eff',
        // warning: '#EFAC00',
        error: '#FF5252',
        success: '#6BAC5A',
      },
    },
  },
  icons: {
    iconfont: 'mdi',
  },
};

// @ts-ignore
export default new Vuetify(opts);
