/**
 * Модель прав для отрисовки дерева чекбоксов в ролях
 */
export const permissions = [
  {
    moduleName: 'Сотрудники',
    id: 1,
    modulePermissions: [
      {
        label: 'Создавать, читать, редактировать, архивировать сотрудников',
        id: 'employee_crud',
      },
      {
        label: 'Создавать, читать, редактировать, удалять ставки',
        id: 'employee_rate_crud',
      },
    ],
  },
  {
    moduleName: 'Проекты',
    id: 2,
    modulePermissions: [
      {
        label: 'Создавать, читать, редактировать, удалять проекты',
        id: 'project_crud',
      },
      {
        label: 'Создавать, читать, редактировать, удалять проекты (только свои)',
        id: 'project_own_crud',
      },
      {
        label: 'Читать проекты (все)',
        id: 'project_read',
      },
      {
        label: 'Создавать, читать, редактировать, удалять заказы',
        id: 'project_task_crud',
      },
      {
        label: 'Создавать, читать, редактировать, удалять заказы (только свои)',
        id: 'project_task_own_crud',
      },
      {
        label: 'Читать заказы (все)',
        id: 'project_task_read',
      },
      {
        label: 'Закреплять/откреплять сотрудника к заказу',
        id: 'project_pin_unpin_emp',
      },
    ],
  },
  {
    moduleName: 'Ресурсы',
    id: 3,
    modulePermissions: [
      {
        label: 'Закреплять/откреплять сотрудника к заказу (Lead)',
        id: 'resources_pin_unpin_lead',
      },
    ],
  },
  {
    moduleName: 'Отчеты',
    id: 4,
    modulePermissions: [
      {
        label: 'Создавать, читать отчеты',
        id: 'report_crud',
      },
    ],
  },
  {
    moduleName: 'Ролевое управление',
    id: 5,
    modulePermissions: [
      {
        label: 'Создавать, читать, редактировать, удалять роли',
        id: 'role_management_crud',
      },
      {
        label: 'Читать, редактировать',
        id: 'role_management_ru',
      },
    ],
  },
];

/**
 * Модель прав для модулей
 * Vuex геттер auth/hasRoleAccess возвращает true, если есть хотя бы одно разрешение для доступа к модулю
 * nav - отрисовка бокового меню
 */
export const permissionsRoutes = {
  staff: {
    nav: ['employee_crud', 'employee_rate_crud'],
    employees: ['employee_crud'],
    specialistRates: ['employee_rate_crud'],
  },
  projects: {
    nav: [
      'project_crud',
      'project_own_crud',
      'project_read',
      'project_task_crud',
      'project_task_own_crud',
      'project_task_read',
      'project_pin_unpin_emp',
    ],
    id: [
      'project_crud',
      'project_own_crud',
      'project_read',
      'project_task_crud',
      'project_task_own_crud',
      'project_task_read',
      'project_pin_unpin_emp',
    ],
    createProject: [
      'project_crud',
      'project_own_crud',
    ],
    editProject: [
      'project_crud',
      'project_own_crud',
    ],
    taskRead: [
      'project_task_read',
      'project_task_crud',
      'project_task_own_crud',
    ],
    editProjectTask: [
      'project_task_crud',
      'project_task_own_crud',
    ],
    createProjectTask: [
      'project_task_crud',
      'project_task_own_crud',
    ],
  },
  plan: {
    nav: ['resources_pin_unpin_lead'],
  },
  reports: {
    nav: ['report_crud'],
  },
  roles: {
    nav: ['role_management_crud', 'role_management_ru'],
    roleCreate: ['role_management_crud'],
    roleEdit: ['role_management_crud', 'role_management_ru'],
  },
};
