import Vue from 'vue';
import VueRouter from 'vue-router';
import store from '../store/index';
import { permissionsRoutes } from '../core/constants/permissions';

Vue.use(VueRouter);

const routes = [
  {
    path: '/login',
    name: 'Login',
    hideOnNavbar: true,
    component: () => import('../views/Login.vue'),
    meta: { layout: 'empty', onlyNotAuthorized: true },
  },
  {
    path: '/forgot-password',
    name: 'ForgotPassword',
    hideOnNavbar: true,
    component: () => import('../views/ForgotPassword.vue'),
    meta: { layout: 'empty', onlyNotAuthorized: true },
  },
  {
    path: '/reset-password/:userId/:token/',
    name: 'Restore',
    hideOnNavbar: true,
    component: () => import('../views/Restore.vue'),
    meta: { layout: 'empty', onlyNotAuthorized: true },
  },
  {
    path: '/',
    name: 'MainPage',
    hideOnNavbar: true,
    component: () => import('../views/main/MainPage.vue'),
    meta: { layout: 'admin' },
  },
  {
    path: '/staff',
    name: 'StaffMain',
    title: 'Сотрудники',
    icon: 'mdi-account-multiple',
    component: () => import('../views/staff/StaffMain.vue'),
    meta: { layout: 'admin', permissions: permissionsRoutes.staff.nav },
  },
  {
    path: '/staff/employees',
    name: 'Employees',
    hideOnNavbar: true,
    component: () => import('../views/staff/Employees.vue'),
    meta: { layout: 'admin', permissions: permissionsRoutes.staff.employees },
  },
  {
    path: '/staff/specialist-rates',
    name: 'SpecialistRates',
    hideOnNavbar: true,
    component: () => import('../views/staff/SpecialistRates.vue'),
    meta: { layout: 'admin', permissions: permissionsRoutes.staff.specialistRates },
  },
  {
    path: '/staff/staff-workload',
    name: 'StaffWorkload',
    hideOnNavbar: true,
    component: () => import('../views/staff/StaffWorkload.vue'),
    meta: { layout: 'admin' },
  },
  {
    path: '/projects',
    name: 'Projects',
    title: 'Проекты',
    icon: 'mdi-clipboard-edit',
    component: () => import('../views/projects/Projects.vue'),
    meta: { layout: 'admin', permissions: permissionsRoutes.projects.nav },
  },
  {
    path: '/projects/:id',
    name: 'Project',
    component: () => import('../views/projects/Project.vue'),
    props: true,
    hideOnNavbar: true,
    meta: { layout: 'admin', permissions: permissionsRoutes.projects.id },
  },
  {
    path: '/createProject',
    name: 'createProject',
    hideOnNavbar: true,
    component: () => import('../views/projects/CreateProject.vue'),
    meta: { layout: 'admin', permissions: permissionsRoutes.projects.createProject },
  },
  {
    path: '/project/edit/:id',
    name: 'EditProject',
    component: () => import('../views/projects/EditProject.vue'),
    props: true,
    hideOnNavbar: true,
    meta: { layout: 'admin', permissions: permissionsRoutes.projects.editProject },
  },
  {
    path: '/projects/:pid/tasks/:id',
    name: 'EditProjectTask',
    component: () => import('../views/projects/EditProjectTask.vue'),
    props: true,
    hideOnNavbar: true,
    meta: { layout: 'admin', permissions: permissionsRoutes.projects.editProjectTask },
  },
  {
    path: '/project/:id/create-task',
    name: 'CreateProjectTask',
    hideOnNavbar: true,
    component: () => import('../views/projects/components/createProjectTask.vue'),
    meta: { layout: 'admin', permissions: permissionsRoutes.projects.createProjectTask },
  },
  {
    path: '/plan',
    name: 'PlanMain',
    title: 'Ресурсы',
    icon: 'mdi-book-multiple-outline',
    component: () => import('../views/plan/PlanMain.vue'),
    meta: { layout: 'admin', permissions: permissionsRoutes.plan.nav },
  },
  {
    path: '/reports',
    name: 'ReportsMain',
    title: 'Отчеты',
    icon: 'mdi-chart-bar',
    component: () => import('../views/reports/ReportsMain.vue'),
    meta: { layout: 'admin', permissions: permissionsRoutes.reports.nav },
  },
  {
    path: '/roles',
    name: 'RolesMain',
    title: 'Роли',
    icon: 'mdi-account-question',
    component: () => import('../views/roles/RolesMain.vue'),
    meta: { layout: 'admin', permissions: permissionsRoutes.roles.nav },
  },
  {
    path: '/roles/create',
    name: 'RoleCreate',
    hideOnNavbar: true,
    component: () => import('../views/roles/RoleCreate.vue'),
    meta: { layout: 'admin', permissions: permissionsRoutes.roles.roleCreate },
  },
  {
    path: '/roles/edit/:id',
    name: 'RoleEdit',
    hideOnNavbar: true,
    component: () => import('../views/roles/RoleEdit.vue'),
    meta: { layout: 'admin', permissions: permissionsRoutes.roles.roleEdit },
  },
  {
    path: '*',
    name: 'Error404',
    hideOnNavbar: true,
    component: () => import('../views/Error404.vue'),
    meta: { layout: 'admin' },
  },
  {
    path: '/403',
    name: 'Error403',
    hideOnNavbar: true,
    component: () => import('../views/Error403.vue'),
    meta: { layout: 'admin' },
  },
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
});

router.beforeEach(async (to, from, next) => {
  const token = store.getters['auth/token'];
  const hasRoleAccess = store.getters['auth/hasRoleAccess'];

  if (token) {
    store.commit('auth/setInitialized');
    if (to.meta.onlyNotAuthorized) {
      return next(from);
    }

    if (to.meta.permissions && !hasRoleAccess(to.meta.permissions)) return next({ name: 'Error403' });
    return next();
  }
  if (!to.meta.onlyNotAuthorized) return next({ name: 'Login' });

  next();
});

export default router;
