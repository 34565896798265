<template>
  <v-dialog
    :value="showModal"
    width="526"
    @click:outside="$emit('closeModal', false)"
  >
    <v-card class="modal-container">
      <v-btn
        icon
        class="modal__close"
        @click="$emit('closeModal', false)"
      >
        <v-icon>mdi-close</v-icon>
      </v-btn>

      <p class="modal__title text-center">Изменение прав доступа</p>
      <p class="modal__sub-title" v-if="modalData">
        <span>В рамках ваших ролей «{{ modalData.user_roles.join(', ') }}» были изменены права:</span>
        <ul class="modal__list mb-3">
          <li v-for="permission in getModulesNames(modalData.change_permission)" :key="'o-'+permission">{{ permission }}</li>
        </ul>
        <span>теперь вы можете:</span>
        <ul class="modal__list">
          <li v-for="permission in getModulesNames(permissions)" :key="'c-'+permission">{{ permission }}</li>
        </ul>
      </p>

      <div class="card-actions d-flex justify-center">
        <v-btn
          color="#212121"
          dark
          class="mt-2 modal__btn modal__btn--auto"
          x-large
          @click="$emit('closeModal', false)"
        >
          Понятно
        </v-btn>
      </div>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapGetters, mapState } from 'vuex';
import notification from '@/mixins/notification';

export default {
  name: 'PermissionsChangeModal',
  mixins: [notification],
  props: {
    showModal: {
      type: Boolean,
      default: false,
    },
    modalData: {
      default: null,
    },
  },

  computed: {
    ...mapState('auth', ['permissions']),
    ...mapGetters('roles', ['getPermissionName']),
    getModulesNames() {
      return (permissions) => permissions.map((m) => this.getPermissionName(m)).filter((f) => f);
    },
  },
};
</script>

<style scoped>
.modal__list {
  font-size: 12px;
  line-height: 16px;
  margin-top: 10px;
}
</style>
