const ID_ACCESS_TOKEN = 'token';
const ID_REFRESH_TOKEN = 'refresh_token';

export const getToken = (refresh = false) => {
  if (refresh) return window.localStorage.getItem(ID_REFRESH_TOKEN);
  return window.localStorage.getItem(ID_ACCESS_TOKEN);
};

export const saveTokens = (data) => {
  window.localStorage.setItem(ID_ACCESS_TOKEN, data.access);
  window.localStorage.setItem(ID_REFRESH_TOKEN, data.refresh);
};

export const destroyTokens = () => {
  window.localStorage.removeItem(ID_ACCESS_TOKEN);
  window.localStorage.removeItem(ID_REFRESH_TOKEN);
};

export default { getToken, saveTokens, destroyTokens };
