import {
  // eslint-disable-next-line no-unused-vars
  nameRules, emailRules, passwordRules, statusCode,
} from '@/core/validationHelpers';

export default {
  data() {
    return {
      nameRules: nameRules(),
      emailRules: emailRules(),
      passwordRules: passwordRules(),
    };
  },

  methods: {
    validateStatusCode(res) {
      return statusCode(res);
    },
    isDatesPeriodValid(dateStart, dateEnd, fnFalse, fnTrue) {
      if (dateEnd && dateEnd !== '') {
        const [day, month, year] = dateEnd.split('.');
        const [dayS, monthS, yearS] = dateStart.split('.');
        const time = new Date(`${year}-${month}-${day}`).getTime();
        const timeS = new Date(`${yearS}-${monthS}-${dayS}`).getTime();

        if (timeS > time) {
          fnFalse();
        } else {
          fnTrue();
        }
      } else {
        fnTrue();
      }
    },
  },
};
