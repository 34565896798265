import apiClient from '@/services/api/apiClient';

export default {
  getProjects: (payload) => apiClient.get('/projects/', { params: payload }),
  getProject: (pid) => apiClient.get(`/projects/${pid}/`),
  createProject: (payload) => apiClient.post('/projects/', payload),
  editProject: (payload) => apiClient.put(`/projects/${payload.id}/`, payload),
  deleteProject: (id) => apiClient.delete(`/projects/${id}/`),
  getProjectTasks: (pid) => apiClient.get(`/projects/${pid}/tasks/`),
  getProjectTask: (payload) => apiClient.get(`/projects/${payload.pid}/tasks/${payload.id}/`),
  createProjectTask: (payload) => apiClient.post(`/projects/${payload.id}/tasks/`, { ...payload }),
  editProjectTask: (payload, pid) => apiClient.put(`/projects/${pid}/tasks/${payload.id}/`, payload),
  deleteProjectTask: (payload) => apiClient.delete(`/projects/${payload.pid}/tasks/${payload.id}/`),
  getProjectFromJIRA: (token) => apiClient.get(`/sync/project/check/${token}/`),
};
