import Vue from 'vue';
import Notifications from 'vue-notification';
import VuetifyMask from 'vuetify-mask';
import App from './App.vue';
import router from './router';
import store from './store';
import vuetify from './plugins/vuetify';
import './assets/styles/main.scss';
import validation from '@/mixins/validation';
import manageAccess from '@/mixins/manageAccess';

Vue.use(VuetifyMask);
Vue.use(Notifications);
Vue.mixin(validation);
Vue.mixin(manageAccess);

Vue.config.productionTip = false;

const Emitter = new Vue();
Vue.prototype.$emitter = Emitter;
window['global-emitter'] = Emitter;

new Vue({
  router,
  store,
  vuetify,
  render: (h) => h(App),
}).$mount('#app');
