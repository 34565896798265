import Vue from 'vue';
import Vuex from 'vuex';
import auth from './auth';
import projects from '@/store/modules/projects';
import staff from '@/store/modules/staff';
import plan from '@/store/modules/plan';
import roles from '@/store/modules/roles';
import general from '@/services/api/general';

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    appWidth: false,
    times: [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16],
    filters: null,
    loading: false,
  },
  mutations: {
    setAppWidth(state, value) {
      state.appWidth = value;
    },
    setFilters(state, value) {
      state.filters = value;
    },
    setLoading(state, value) {
      state.loading = value;
    },
  },
  actions: {
    async getFilters({ commit }) {
      commit('setLoading', true);
      try {
        const res = await general.getFilters();
        commit('setFilters', res.data);
        return res;
      } catch (error) {
        return error;
      } finally {
        commit('setLoading', false);
      }
    },
  },
  modules: {
    auth,
    projects,
    staff,
    plan,
    roles,
  },
});
