<template>
  <notifications classes="custom-notify" :group="group">
    <template slot="body" slot-scope="props">
      <div
        class="custom-notify-item"
        :class="{ 'custom-notify-item--success': group === 'success',
          'custom-notify-item--warning': group === 'warning'}"
      >
        <span class="mr-2" v-html="props.item.text"></span>
        <v-icon color="#D6D6D6" @click="props.close">mdi-close-circle</v-icon>
      </div>
    </template>
  </notifications>
</template>

<script>
export default {
  name: 'Notify',
  props: {
    group: {
      type: String,
      default: 'error',
    },
  },
};
</script>
