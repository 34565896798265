import { mapGetters, mapState } from 'vuex';
import { permissionsRoutes } from '@/core/constants/permissions';

export default {
  data() {
    return {
      permissionsRoutes,
    };
  },
  computed: {
    ...mapState('auth', ['permissions']),
    ...mapGetters('auth', ['hasRoleAccess']),
  },
  methods: {
    checkPageAccess() {
      const route = this.$route;
      if (route.meta && route.meta.permissions) {
        if (!this.hasRoleAccess(route.meta.permissions)) {
          this.$router.push({ name: 'Error403' });
        }
      }
    },
  },
};
