<template>
  <div>
    <div v-if="initialized">
      <component :is="layoutName" />
      <Notify group="error" />
      <Notify group="success" />
      <Notify group="warning" />
    </div>
    <v-app v-else>
      <v-overlay color="#FFFFFF">
        <v-progress-circular
          :size="50"
          color="primary"
          indeterminate
        />
      </v-overlay>
    </v-app>
    <PermissionsChangeModal @closeModal="closeModal" :modalData="modalData" :showModal="showPermissionsChange" />
  </div>
</template>

<script>
import { mapGetters, mapMutations, mapActions } from 'vuex';
import AdminLayout from '@/components/layouts/AdminLayout.vue';
import EmptyLayout from '@/components/layouts/EmptyLayout.vue';
import PermissionsChangeModal from '@/components/UI/PermissionsChangeModal.vue';
import Notify from '@/components/Notify.vue';

export default {
  components: {
    AdminLayout,
    EmptyLayout,
    Notify,
    PermissionsChangeModal,
  },

  data() {
    return {
      showPermissionsChange: false,
      modalData: null,
    };
  },

  computed: {
    ...mapGetters({
      token: 'auth/token',
      initialized: 'auth/initialized',
    }),
    layoutName() {
      return `${(this.$route.meta.layout || 'empty')}-layout`;
    },
  },

  methods: {
    ...mapMutations({
      setAppWidth: 'setAppWidth',
      setInitialized: 'auth/setInitialized',
    }),
    ...mapActions('auth', ['refresh']),
    closeModal() {
      this.showPermissionsChange = false;
      this.modalData = null;
      this.checkPageAccess();
    },
  },

  updated() {
    this.setAppWidth(window.screen.width);
  },

  mounted() {
    this.setAppWidth(window.screen.width);
  },
  created() {
    if (!this.token) this.setInitialized();
    this.$emitter.$on('showPermissionsChange', (data) => {
      this.modalData = data;
      this.refresh().then(() => {
        this.showPermissionsChange = true;
      });
    });

    this.$emitter.$on('err403', () => {
      this.$router.push({ name: 'Error403' });
    });
  },
};
</script>
