import apiClient from '@/services/api/apiClient';

export default {
  getUsers: () => apiClient.get('/staff'), // Эндпоинта пока нет
  getManagers: (params) => apiClient.get('/account/users/', { params }),
  putManagers: (params) => apiClient.put(`/account/users/${params.id}/`, params),
  getSpecialistKind: () => apiClient.get('/specialist-kind/'),
  createUser: (params) => apiClient.post('/account/employees/', params),
  createPM: (params) => apiClient.post('/account/users/', params),
  getPositions: () => apiClient.get('/account/employees/positions/'), // Получение ставок(должностей)
  addPositions: (params) => apiClient.put('/account/employees/positions/', params),
};
