export default {
  methods: {
    showErrorNotification(text) {
      this.$notify({
        group: 'error',
        type: 'error',
        title: 'Error',
        text,
        duration: 5000,
        speed: 500,
      });
    },

    showSuccessNotification(text) {
      this.$notify({
        group: 'success',
        type: 'success',
        title: 'Success',
        text,
        duration: 5000,
        speed: 500,
      });
    },

    showWarningNotification(text) {
      this.$notify({
        group: 'warning',
        type: 'warning',
        title: 'Warning',
        text,
        duration: 5000,
        speed: 500,
      });
    },
  },
};
