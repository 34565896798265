/**
  * Получения цены в формате 1 000 000
  */
export function getPrice(value) {
  const str = String(value)
    .replace(/\s+/g, '')
    .split('')
    .reverse()
    .map((el, index) => ((index + 1) % 3 === 0 ? ` ${el}` : el))
    .reverse()
    .join('')
    .trim();
  return `${str}`;
}
/**
  *  Функция преобразовывает число в строку в формате 06
  */
export function zeroPadded(num) {
  return +num < 10 ? `0${+num}` : `${+num}`;
}

/**
 * Костыль для преобразования непонятно чего в название офиса
 */
export function officeNormalize(value) {
  if (value === '1') return 'Москва';
  if (value === '2') return 'Минск';
  if (value === 'Беларусь') return 'Минск';
  return value;
}

export function parseJwt(token) {
  if (!token && typeof token !== 'string') return false;
  const base64Url = token.split('.')[1];
  const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
  const jsonPayload = decodeURIComponent(atob(base64).split('').map((c) => `%${(`00${c.charCodeAt(0).toString(16)}`).slice(-2)}`).join(''));

  return JSON.parse(jsonPayload);
}
