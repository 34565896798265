import api from '@/services/api/plan';

export default {
  namespaced: true,
  state: {
    table: [],
    planTab: 0,
  },
  actions: {
    async getTable({ commit }, payload) {
      try {
        const res = await api.getTable(payload);
        commit('setTable', res.data);
        return res;
      } catch (error) {
        return error;
      }
    },
    async postWorkLoad({ commit }, payload) {
      try {
        const res = await api.postWorkLoad(payload);
        console.log(res, commit);
        return res;
      } catch (error) {
        return error;
      }
    },
    async postProjectForUser({ commit }, payload) {
      try {
        const res = await api.postProjectForUser(payload);
        console.log(res, commit);
        return res;
      } catch (error) {
        return error;
      }
    },

    async postProjectForAbstract({ commit }, payload) {
      try {
        const res = await api.postProjectForAbstract(payload);
        console.log(res, commit);
        return res;
      } catch (error) {
        return error;
      }
    },

    async deleteEmployeeFromProject({ commit }, payload) {
      try {
        const res = await api.deleteEmployee(payload);
        console.log(res, commit);
        return res;
      } catch (error) {
        return error;
      }
    },
  },
  mutations: {
    setTable: (state, value) => {
      state.table = value;
    },
    setPlanTab: (state, value) => {
      state.planTab = value;
    },
  },
};
